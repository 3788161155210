import {
  ChangeEvent,
  Suspense,
  useCallback,
  useContext,
  useRef
} from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { SubmitHandler, useForm } from "react-hook-form";
import { Navigate, useLocation, useNavigate, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import useApi from "../hooks/useApi";
import { AppContext } from "../providers/AppProvider";
import { resizeFile } from "../utils";

export function Component() {
  const formRef = useRef<HTMLFormElement>(null);

  const navigate = useNavigate();
  const location = useLocation();
  const params = useParams();

  const { executeRecaptcha } = useGoogleReCaptcha();

  const app = useContext(AppContext);
  const api = useApi();

  const { register, handleSubmit, setValue, setError, formState } = useForm({
    defaultValues: {
      arquivo: "",
      arquivo_cupom_fiscal: "",
      recaptcha_token: "",
    },
  });

  const handleUpload = async (event: ChangeEvent<HTMLInputElement>) => {
    try {
      const file = event.target.files?.[0];

      if (file) {
        const image = await resizeFile(file);
        setValue("arquivo_cupom_fiscal", image);
      }
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Ocorreu um erro ao processar o arquivo. Por favor, tente novamente.",
      });
    }
  };

  const handleSuccess = useCallback(
    (response: any) => {
      Swal.close();

      Swal.fire({
        icon: "success",
        title: "Sucesso!",
        text: response?.message ?? "Nota fiscal enviada com sucesso. Aguarde o nosso retorno por e-mail com a validação.",
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
      }).then(() => {
        navigate(response?.redirectTo ?? "/meus-numeros");
      });
    },
    [navigate],
  );

  const handleError = useCallback(
    (error: any) => {
      Object.entries<any>(error.data).forEach(([field, messages]) => {
        if (field === "arquivo_cupom_fiscal") {
          setError("arquivo", { type: "manual", message: messages[0] });
        } else {
          setError(field as any, { type: "manual", message: messages[0] });
        }
      });

      Swal.fire({
        icon: "error",
        title: "Oops...",
        html: error.message,
      });
    },
    [setError],
  );

  const onSubmit: SubmitHandler<any> = useCallback(
    async (data) => {
      if (!executeRecaptcha) {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Ocorreu um erro ao carregar o reCAPTCHA. Por favor, recarregue a página e tente novamente.",
        });

        return;
      }

      Swal.fire({
        title: "Aguarde...",
        text: "Estamos processando o envio da sua nota fiscal.",
        showConfirmButton: false,
        allowOutsideClick: false,
        allowEscapeKey: false,
        allowEnterKey: false,
        willOpen: () => {
          Swal.showLoading();
        },
      });

      data.recaptcha_token = await executeRecaptcha("submit");

      api.uploadCupom(params.id, data).then(handleSuccess).catch(handleError);
    },
    [api, executeRecaptcha, handleError, handleSuccess, params.id],
  );

  if (!app.isAuthenticated) {
    return <Navigate to="/login" state={{ location }} replace />;
  }

  return (
    <Suspense fallback={<h1>Carregando...</h1>}>
      <section>
        <Container className="mb-5">
          <h1 className="text-center">NOTA FISCAL</h1>

          <Row>
            <Col xs={12} md={{ span: 8, offset: 2 }}>
              <Form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
                <Col xs={12} className="mb-3">
                  <Form.Label>Selecione o arquivo*</Form.Label>
                  <Form.Control
                    type="file"
                    id="arquivo"
                    isInvalid={!!formState.errors.arquivo}
                    accept="image/png, image/gif, image/jpeg"
                    {...register("arquivo", {
                      required: "Este campo é obrigatório",
                      onChange: handleUpload,
                    })}
                  />
                  <Form.Text>
                    Tire uma foto do seu cupom fiscal - Formato .jpg ou .png até
                    6MB
                  </Form.Text>
                  <Form.Control.Feedback type="invalid">
                    {formState.errors.arquivo?.message}
                  </Form.Control.Feedback>
                </Col>

                <Button variant="primary" type="submit">
                  Enviar notal fiscal
                </Button>
              </Form>
            </Col>
          </Row>
        </Container>
      </section>
    </Suspense>
  );
}
